// document.addEventListener("turbolinks: load", function () {
//     var options = {
//         data: ["John", "Paul", "George", "Ringo"]
//     };
//     $('*[data-behavior="autocomplete"]').easyAutocomplete(options);
// });


social = null

document.addEventListener("turbolinks:load", function() {
    social = $('#social').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });

  document.addEventListener("turbolinks:before-cache", function() {
    if (social !== null) {
        social.destroy();
        social = null;
    }
} );


// cash = null

// document.addEventListener("turbolinks:load", function() {
//     cash = $('#cash').DataTable({
//             stateSave: true,
//             responsive: true
//         })
//         .columns.adjust()
//         .responsive.recalc();
// });

// document.addEventListener("turbolinks:before-cache", function() {
//     if (cash !== null) {
//         cash.destroy();
//         cash = null;
//     }
// });


employee = null

document.addEventListener("turbolinks:load", function() {
    employee = $('#employee').DataTable({
            stateSave: true,
            responsive: true
        })
        .columns.adjust()
        .responsive.recalc();
});

document.addEventListener("turbolinks:before-cache", function() {
    if (employee !== null) {
        employee.destroy();
        employee = null;
    }
});


dataTable = null

document.addEventListener("turbolinks:load", function() {
    dataTable = $('#example').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


document.addEventListener("turbolinks:before-cache", function() {
    if (dataTable !== null) {
        dataTable.destroy();
        dataTable = null;
    }
} );

dataTable1 = null
document.addEventListener("turbolinks:load", function() {
    dataTable1 = $('#example1').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (dataTable1 !== null) {
        dataTable1.destroy();
        dataTable1 = null;
    }
} );

final = null
document.addEventListener("turbolinks:load", function() {
    final = $('#final').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (final !== null) {
        final.destroy();
        final = null;
    }
} );


audit = null
document.addEventListener("turbolinks:load", function() {
    audit = $('#audit').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (audit !== null) {
        audit.destroy();
        audit = null;
    }
} );


dm = null
document.addEventListener("turbolinks:load", function() {
    dm = $('#dm').DataTable( {
        stateSave: true,
        responsive: true
    } )
    .columns.adjust()
    .responsive.recalc();
  });


  document.addEventListener("turbolinks:before-cache", function() {
    if (dm !== null) {
        dm.destroy();
        dm = null;
    }
} );


document.addEventListener("turbolinks:load", function() {
    entries = $('#entries-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#entries-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    // {"data": "check_box"},
    {"data": "entry_code"},
    {"data": "description"},
    {"data": "document_type"},
    {"data": "date"},
    {"data": "show"}
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
        orderable: true
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});

// document.addEventListener("turbolinks:before-cache", function() {
//     if (entries !== null) {
//         entries.destroy();
//         entries = null;
//     }
// } );


document.addEventListener("turbolinks:load", function()  {
    invoice = $('#inventory-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#inventory-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "vehicle_cs_no"},
    {"data": "vehicle_model"},
    {"data": "vehicle_color"},
    {"data": "vehicle_id_no"},
    {"data": "customer_id"},
    {"data": "show"}
    ],
    "responsive": true,
    "rowCallback": function( row, data ) {
    if ( data.customer_id === "OPEN" ) {
    $('td:eq(4)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
    $('td:eq(4)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
    $('td:eq(4)', row).html( 'OPEN' );
    } else {
    $('td:eq(4)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
    $('td:eq(4)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
    $('td:eq(4)', row).html( 'SOLD' );
    }
    },
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
    }
    ],
    dom: 'Bfrtip',
    lengthMenu: [
        [ 10, 25, 50],
        [ '10 rows', '25 rows', '50 rows']
    ],
    stateSave: true,
    buttons: [
        // { extend: 'copy', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        // { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        'colvis',
        'pageLength'
    ]
});

});



document.addEventListener("turbolinks:load", function()  {
    invoice = $('#customer-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#customer-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "customer_id"},
    {"data": "customer_name"},
    {"data": "site"},
    {"data": "segment"},
    {"data": "show"}
    ],
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
    }
    ],
    dom: 'Bfrtip',
    lengthMenu: [
        [ 10, 25, 50],
        [ '10 rows', '25 rows', '50 rows']
    ],
    stateSave: true,
    buttons: [
        // { extend: 'copy', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        // { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        'colvis',
        'pageLength'
    ]
});

});


document.addEventListener("turbolinks:load", function()  {
    invoice = $('#invoice-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#invoice-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "invoice_number"},
    {"data": "site"},
    {"data": "customer"},
    {"data": "segment"},
    {"data": "sales"},
    {"data": "discount"},
    {"data": "vat"},
    {"data": "ar_amount"},
    {"data": "show"}
    ],
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
    }
    ],
    dom: 'Bfrtip',
    lengthMenu: [
        [ 10, 25, 50],
        [ '10 rows', '25 rows', '50 rows']
    ],
    stateSave: true,
    buttons: [
        // { extend: 'copy', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        // { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        'copy',
        'colvis',
        'pageLength'
    ]
});

});
document.addEventListener("turbolinks:load", function()  {
    $('#customer-invoice-table1').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#customer-invoice-table1').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "invoice_number"},
    {"data": "site"},
    {"data": "customer"},
    {"data": "segment"},
    {"data": "sub_segment"},
    {"data": "sales"},
    {"data": "discount"},
    {"data": "vat"},
    {"data": "ar_amount"},
    {"data": "show"}
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});

document.addEventListener("turbolinks:load", function()  {
    $('#customer-invoice-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#customer-invoice-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "invoice_number"},
    {"data": "site"},
    {"data": "customer"},
    {"data": "segment"},
    {"data": "sales"},
    {"data": "discount"},
    {"data": "vat"},
    {"data": "ar_amount"},
    {"data": "show"}
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500'
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});


document.addEventListener("turbolinks:load", function() {
    entries = $('#pending-entries-table').dataTable({
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": $('#pending-entries-table').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            // {"data": "check_box"},
            {"data": "entry_code"},
            {"data": "site"},
            {"data": "customer_name"},
            {"data": "description"},
            {"data": "document_type"},
            {"data": "date"},
              // Add this line to display account_name
            {"data": "show"}
        ],
        "responsive": true,
        columnDefs: [
            {
                targets: "_all",
                className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
                orderable: true
            }
        ],
        dom: 'Bfrtip',
        stateSave: true,
        buttons: [
            { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        ]
    });
});



document.addEventListener("turbolinks:load", function() {
    entries = $('#failed-entries-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#failed-entries-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    // {"data": "check_box"},
    {"data": "entry_code"},
    {"data": "description"},
    {"data": "document_type"},
    {"data": "date"}
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
        orderable: true
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});

document.addEventListener("turbolinks:load", function() {
    entries = $('#payment-entries-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#payment-entries-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "payment_code"},
    {"data": "customer"},
    {"data": "payment_mode"},
    {"data": "payment_amount"},
    {"data": "payment_balance"},
    {"data": "collection_date"},
    {"data": "deposited"},
    {"data": "show"},
    ],
    "responsive": true,
    "rowCallback": function( row, data ) {
    if ( data.deposited === "false" ) {
    $('td:eq(6)', row).css('background-color', 'rgb(254 226 226 / var(--tw-bg-opacity))');
    $('td:eq(6)', row).css('color', 'rgb(153 27 27 / var(--tw-text-opacity))');
    $('td:eq(6)', row).html( 'Pending' );
    } else if ( data.deposited === "true" ) {
    $('td:eq(6)', row).css('background-color', 'rgb(220 252 231 / var(--tw-bg-opacity))');
    $('td:eq(6)', row).css('color', 'rgb(22 101 52 / var(--tw-text-opacity))');
    $('td:eq(6)', row).html( 'Deposited' );
    }
    },
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        'copy',
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});

// document.addEventListener("turbolinks:before-cache", function() {
//     if (invoice !== null) {
//         invoice.destroy();
//         invoice = null;
//     }
// } );

document.addEventListener("turbolinks:load", function() {
    entries = $('#chart-account-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#chart-account-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "type"},
    {"data": "sub_type"},
    {"data": "account_code"},
    {"data": "name"},
    {"data": "contra"},
    {"data": "balance"},   
    {"data": "show"},
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
        orderable: true
    }
    ],
    dom: 'Bfrtip',
    lengthMenu: [
        [ 10, 25, 50],
        [ '10 rows', '25 rows', '50 rows']
    ],
    stateSave: true,
    buttons: [
        'copy',
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
        'pageLength'
    ]
});

});

document.addEventListener("turbolinks:load", function() {
    entries = $('#sub-ledger-table').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
    "url": $('#sub-ledger-table').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
    {"data": "je"},
    {"data": "customer_name"},
    {"data": "document_type"},
    {"data": "type"},
    {"data": "amount"},
    {"data": "cost_profit"},
    {"data": "show"},
    ],
    "responsive": true,
    columnDefs: [
    {
        targets: "_all",
        className: 'px-6 py-4 whitespace-no-wrap border-b text-sm leading-5 text-gray-500 select-checkbox',
        orderable: true
    }
    ],
    dom: 'Bfrtip',
    stateSave: true,
    buttons: [
        { extend: 'colvis', className: 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' },
    ]
});

});
